import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected'
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const NeededFromOwnerForWebPages = ({ colors = {} }) => {

    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;

    const myAnimationOptionsCCh0 = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="material-for-web-pages" className="toc"><span>Čo potrebujeme pre vaše webstránky</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh0}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="material-for-web-pages"
            >
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="ciel" className="waved toc toc0"><span>Cieľ web stránok</span></h4>
                    <div>Aký je hlavný cieľ web stránok - či už ide o predaj produktov, poskytovanie informácií, blogovanie, prezentáciu portfólia, atď.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="cielova-skupina" className="waved toc toc0"><span>Cielení návštevníci</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>Kto majú byť hlavní návštevníci stránky? Aké sú ich záujmy, vek?</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="obsah" className="waved toc toc0"><span>Obsah</span></h4>
                    <div>Všetok potrebný obsah pre stránku, vrátane textov, fotografií, videí, loga a ďalších multimediálnych materiálov.</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="design-style" className="waved toc toc0"><span>Dizajn a Štýl</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>Aké sú Vaše preferencie na dizajn a štýl stránky? Existujú nejaké konkrétne farby, písma alebo iné dizajnové prvky, ktoré by mali byť použité?</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="funkcionalita" className="waved toc toc0"><span>Funkcionalita</span></h4>
                    <div>Aké funkcie by mali webstránky obsahovať? Napríklad, potrebuje e-shop, kontaktný formulár, blog, rezervačný systém, atď.?</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        nameWavedFrames="baseT2R2B2L2_strecha"
                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="seo-marketing" className="waved toc toc0"><span>SEO a marketing</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>Aké sú vaše marketingové a SEO ciele, aby sme mohli optimalizovať webstránky pre vyhľadávače a teda aby vás želaní návštevníci ľahšie našli.</div>
                </div>
                <WavedFrameAnimationForChildrenLazy
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <h4 id="rozpocet" className="waved toc toc0"><span>Cena a termín</span></h4>
                    <div>Aké máte limity na cenu web stránok a termín ich spustenia?</div>
                </WavedFrameAnimationForChildrenLazy>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default NeededFromOwnerForWebPages;